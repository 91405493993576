@import "../../variables.scss";

.project {
  &__top {
    // height: calc(80 * var(--vh));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    padding-top: 50px;

    & h3 {
      margin-bottom: 0;
    }
  }

  &__youtube {
    width: 80%;
    aspect-ratio: 19/6;
    background-color: lightgray;
    margin: 160px 0;
    margin: auto;
  }

  &__title {
    text-align: center;
    margin-bottom: 0;
  }

  &__info-container {
    width: 100%;
    padding-top: 90px;
    animation: fadeIn 400ms;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center !important;

    & img {
      max-width: 50%;
      padding-left: 25%;
      padding-right: 25%;
    }
  }

  &__youtube-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 30px;
    border: none;
    margin: auto;

    & iframe {
      border: none;
    }
  }

  &__youtube {
    max-width: 30%;
    aspect-ratio: 16/9;
    padding: 20px;
    background-color: white;
    & iframe {
      width: 100%;
    }
  }

  &__info-btn {
    text-decoration: none;
    border: 1px solid rgba($darkGreyText, 0.3);
    color: $darkGreyText;
    padding: 5px;
    transition: 300ms;
    // margin-top: 20px;

    &:hover {
      border: 1px solid rgba($darkGreyText, 0.9);
    }
  }
}

.pdf {
  border: none;
  height: 1000px;
  margin: auto;
}
@media (max-width: 900px) {
  .project {
    &__youtube {
      max-width: 40%;
    }
  }
}
@media (max-width: 700px) {
  .project {
    &__top {
      padding-top: 40px;
    }

    &__text {
      & img {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__info-container {
      margin-top: 150px;
      padding-top: 60px;
    }

    &__youtube {
      max-width: 100%;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
