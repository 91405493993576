.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {
    max-width: 500px;
    & img {
      width: 100%;
    }
  }

  &__text {
    max-width: 900px;
  }
}
