@import "../../variables.scss";

.carousel {
  display: flex;
  list-style: none;
  align-items: center;
  overflow: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  max-width: 900px;
  max-height: calc(80 * var(--vh));

  &__chevrons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-bottom: 40px;

    & button {
      background-color: transparent;
      border: none;
      font-size: larger;
      padding: 10px 15px;
      margin: 10px;
      border-radius: 50%;
      cursor: pointer;
      transition: 300ms;
      color: $darkGreyText;
      &:hover {
        background-color: rgb(243, 243, 243);
      }
    }
  }

  & li {
    width: 80%;
    min-height: 1px;

    flex: 0 0 auto;
    scroll-snap-align: center;
    transition: 300ms;
  }

  &__item {
    transition: 500ms;
    overflow: hidden;
    margin: 0 15px;

    &--pdf {
      border: 2px solid #ddd;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
      margin: 0;
    }

    &--side {
      opacity: 0.8;
      transition: 500ms;
      cursor: pointer;
    }
  }

  &__pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    &__button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

ul {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

ul::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

@media (min-width: 900px) {
  .carousel {
    & li {
      width: 60%;
      margin: 0 9%;
    }
  }
}

ul .slide:first-child {
  padding-left: 10%;
}

ul .slide:last-child {
  padding-right: 10%;
}

@media (max-width: 700px) {
  .carousel {
    padding: 0;
    & li {
      width: 100%;
    }
  }
  ul .slide:first-child {
    padding-left: 0px;
  }

  ul .slide:last-child {
    padding-right: 0%;
  }
}
