@import "../../variables.scss";
.nav {
  background-color: white;
  width: 240px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 76px;
  background-color: white;
  padding: 10px 16px;
  border-right: 1px solid #ddd;
  overflow: hidden;
  transition: 300ms ease-in-out;

  &__item {
    color: $darkGreyText;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    transition: 300ms;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 20px 5px;
    padding-left: 20px;

    &:hover {
      border-bottom: 1px solid black;
    }

    &--project {
      background-color: rgba(200, 200, 200, 0.2);
    }
  }

  &--active {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid black;
  }
}

@media (max-width: 900px) {
  .nav {
    position: relative;
    height: fit-content;
    width: 100%;
    padding: 0;

    &__item {
      padding-left: 30px;
    }
  }
}
