@import "../../variables.scss";

.header {
  display: flex;
  justify-content: center;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  right: 0;
  height: 76px;
  background-color: white;
  z-index: 2;

  &__link {
    text-decoration: none;
    color: $darkGreyText;
  }
}

.menu-toggle {
  position: absolute;
  left: 10px;
  top: 15px;
  width: 40px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &__line {
    width: 100%;
    height: 1px;
    background-color: black;
  }
}

@media (min-width: 901px) {
  .menu-toggle {
    display: none;
  }
}
