.biog-page {
  max-width: 1200px;

  &__image {
    width: 70%;
    & img {
      width: 100%;
    }
  }
  &__texts {
    display: flex;

    &__biog {
      margin: 20px;
      min-width: 200px;
    }
  }
}

@media (max-width: 800px) {
  .biog-page {
    &__image {
      width: 100%;
    }
    &__texts {
      flex-direction: column;
    }
  }
}
