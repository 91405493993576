@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");

body {
  font-family: "Cutive Mono", monospace;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 20px;
}

.slider-slide {
  width: 100vw;
  height: 50vh;
  overflow: hidden;
  position: relative;
  opacity: 0.8;
  user-select: none;
}

.slider-slide:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.active {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

img {
  width: 100%;
  max-height: 100vh;
}

.btn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.leftBtn {
  left: 0;
  right: auto;
}

.rightBtn {
  left: auto;
  right: 0;
}

.tab-container {
  display: flex;
  flex-direction: column;
  animation: fade 1s ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
